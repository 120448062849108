<template>
    <template v-for="item in SETTINGS_MENU" :key="item.id">
        <button
            v-if="item.type === 'pane'"
            type="button"
            class="cursor-pointer text-base text-valence-grey-800 hover:text-valence-grey-800/80 flex flex-row items-center gap-5 leading-none"
            @click="$emit('openPane', item)">
            <span class="h-4 w-4"><component :is="item.icon" /></span>
            {{ item.title }}
        </button>
        <component
            :is="componentType(item.type)"
            v-else-if="item.type === 'url' || item.type === 'pageUrl'"
            :href="item.url"
            class="text-base text-valence-grey-800 hover:text-valence-grey-800/80 flex flex-row items-center gap-4 leading-none py-2 md:py-0"
            :class="{ 'md:hidden': item.id === 'profile' }"
            @click="$emit('itemClick')">
            <span v-if="iconIsComponent(item.icon)" class="h-4 w-4"><component :is="item.icon" /></span>
            <span v-else class="bi text-valence-grey-800" :class="item.icon"></span>
            {{ item.title }}
        </component>
    </template>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";
import AlertCircle from "~vue/icons/AlertCircle.vue";
import Logout from "~vue/icons/Logout.vue";
import { isInIFrame } from "~vue/utils.js";
import { inject } from "vue";

const globalProperties = inject("globalProperties");

defineProps({
    isOpen: {
        type: Boolean,
        default: () => false,
    },
});

defineEmits(["openPane", "itemClick"]);

const SETTINGS_MENU = [
    {
        id: "profile",
        title: "Profile",
        type: "pageUrl",
        icon: "bi-person-circle",
        url: "/accounts/user-profile",
    },
    {
        id: "feedback",
        title: "Report an Issue",
        type: "url",
        url: globalProperties.$feedbackMailto,
        icon: AlertCircle,
    },
    {
        id: "logout",
        title: "Logout",
        type: "url",
        // TODO: Before we can upgrade to Django 5, this needs to be converted to a POST request
        // https://docs.djangoproject.com/en/5.0/releases/4.1/#log-out-via-get
        url: "/accounts/logout/",
        icon: Logout,
    },
];

if (isInIFrame()) {
    SETTINGS_MENU.pop();
}

const componentType = (itemType) => {
    if (itemType === "url") {
        return "a";
    } else if (itemType === "pageUrl") {
        return Link;
    }
    return;
};

// type is object if its a vue component, else it will return string for bootstrap icon class
const iconIsComponent = (icon) => typeof icon === "object";
</script>
