<template>
    <div v-if="isVisible" class="fixed w-full h-full bg-black bg-opacity-40 flex items-center">
        <GenericDialog
            ref="sessionExpirationDialog"
            :open="isVisible"
            title="Anybody home?"
            action-button-label="Wait, I'm still here!"
            :action-button-callback="onRenewSession"
            :dismissable="false"
            class="p-8"
        >
            <template #content>
                <p>Your session is about to expire.</p>
            </template>
        </GenericDialog>
    </div>
</template>

<script setup>
import GenericDialog from "~vue/components/GenericDialog.vue";
import { DateTime } from "luxon";
import { onMounted, onUnmounted, ref } from "vue";

import { getCookie } from "../utils";

/** How frequently to check for an about-to-expire session, in milliseconds. */
const SESSION_CHECK_INTERVAL_MS = 10_000;

/** The session warning dialog will appear this many milliseconds before the session expiration time. */
const SESSION_SHOW_WARNING_DIALOG_MS = 60_000;

const EXPIRATION_COOKIE_NAME = "session_expires_at";

const sessionExpirationDialog = ref(null);
const timer = ref(null);
const isVisible = ref(false);

onMounted(() => {
    timer.value = setInterval(async () => {
        const now = DateTime.now();

        if (!window.cookieStore) {
            return;
        }

        const cookie = await window.cookieStore.get(EXPIRATION_COOKIE_NAME);
        if (!cookie || !cookie.value) {
            return;
        }

        const sessionExpiresAt = DateTime.fromISO(cookie.value);
        if (sessionExpiresAt < now) {
            return logout();
        }

        const msRemaining = sessionExpiresAt - now;
        const isInExpirationWindow = msRemaining <= SESSION_SHOW_WARNING_DIALOG_MS;
        isVisible.value = isInExpirationWindow;
    }, SESSION_CHECK_INTERVAL_MS);
});

onUnmounted(() => {
    clearInterval(timer.value);
});

async function onRenewSession() {
    try {
        const response = await fetch("/api/accounts/renew-session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: "{}",
        });
        if (!response.ok) {
            return logout();
        }
    } finally {
        isVisible.value = false;
    }
}

async function logout() {
    // TODO: Before we can upgrade to Django 5, this needs to be converted to a POST request
    // https://docs.djangoproject.com/en/5.0/releases/4.1/#log-out-via-get
    const params = new URLSearchParams({ next: window.location.pathname + window.location.search });
    window.location.href = "/accounts/logout/?" + params;
}
</script>
