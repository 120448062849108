<template>
    <div class="full-height-dynamic flex flex-col xl:flex-row min-w-full relative overflow-y-auto md:overflow-y-hidden antialiased" :class="{ 'fixed overflow-y-hidden': isOpen }">
        <main class="flex flex-col md:flex-row w-full overflow-y-visible md:overflow-y-auto h-full">
            <!-- Desktop sidebar -->
            <LeftSidebar :is-open="isOpen" @toggle="toggle" />
            <div class="relative grow flex overflow-hidden">
                <button
                    data-cy="sidebar-button"
                    type="button"
                    :title="isOpen ? 'Close sidebar' : 'Open sidebar'"
                    class="z-50 hidden md:block absolute top-7 left-5 hover:text-valence-grey-800/75 hover:bg-white/50 rounded-md p-2 transition-color duration-150 ease-in"
                    @click="toggle">
                    <i v-if="isOpen" class="bi bi-chevron-double-left h-5 w-5 text-xl"></i>
                    <i v-else class="bi bi-list h-5 w-5 text-xl"></i>
                </button>
                <div class="flex flex-col grow overflow-hidden">
                    <slot />
                    <MobileMainMenu />
                </div>
            </div>
        </main>
    </div>
    <SessionExpirationDialog />
</template>

<script setup>
import LeftSidebar from "~vue/components/navigation/LeftSidebar.vue";
import MobileMainMenu from "~vue/components/navigation/MobileMainMenu.vue";
import SessionExpirationDialog from "~vue/SessionExpirationDialog.vue";
import { useSideBarStore } from "~vue/stores/sideBarStore";
import { computed } from "vue";

const isOpen = computed(() => useSideBarStore.isOpen);
const toggle = () => useSideBarStore.toggle();
</script>
