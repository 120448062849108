<template>
    <button
        :popovertarget="popoverId"
        popovertargetaction="toggle"
        title="View menu"
        type="button"
        class="hidden md:flex shrink-0 p-5 w-full border-t border-t-gray-100 hover:bg-gray-50/80 text-left flex-row items-center gap-3 peer-popover-open:bg-gray-50">
        <div class="w-10 h-10 shrink-0">
            <i class="bi-person-circle text-3xl text-valence-grey"></i>
        </div>
        <div class="text-valence-grey overflow-hidden grow">
            <p class="text-sm font-medium">{{ userDetails.first_name }} {{ userDetails.last_name }}</p>
            <p :title="userDetails.email" class="text-xs text-ellipsis overflow-hidden" v-text="userDetails.email"></p>
        </div>
    </button>
</template>

<script setup>
import { inject } from "vue";

defineProps({
    popoverId: { type: String, default: "" },
});
const userDetails = inject("user_details");
</script>
