<template>
    <Link
        :href="url"
        :title="button_title"
        class="w-full flex flex-row items-start gap-4 rounded-xl bg-white hover:opacity-95 hover:bg-gray-50/60 hover:shadow-sm border py-4 pl-4 pr-2 shadow-black/5"
        :class="isActive ? 'sidebar-active-card' : 'border-gray-200 shadow-md'"
        @click="handleClick">
        <div class="grow text-left flex flex-col gap-1">
            <div class="font-semibold text-sm">
                {{ title }}
            </div>
            <p v-if="description" class="text-xs" v-text="description"></p>
            <span
                v-if="context"
                class="font-medium text-2xs font-semibold uppercase text-valence-grey-800 bg-valence-light-blue-50 px-2 m-0 rounded-[3px] w-auto self-start mt-2"
                v-text="context">
            </span>
        </div>
        <slot name="button"></slot>
    </Link>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";
import { inject } from "vue";

const { emitter } = inject("globalProperties");

defineProps({
    title: String,
    buttonTitle: String,
    context: String,
    description: String,
    url: String,
    isActive: Boolean,
});

const handleClick = () => {
    emitter.emit("sidebar-card-clicked");
};
</script>
