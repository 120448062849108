<template>
    <div class="hidden md:flex flex-col justify-start px-5 mt-2 grow">
        <template v-for="item in panes" :key="item.id">
            <button
                :data-cy="`nav-${item.id}`"
                type="button"
                class="text-base hover:font-medium hover:bg-valence-panehover flex flex-row items-center gap-5 leading-none rounded-lg p-2"
                @click="handlePaneClick(item)">
                <i class="bi text-valence-grey text-2xl" :class="item.icon"></i>
                {{ item.title }}
                <div v-if="item.id == 'profile'" class="ml-auto space-x-2">
                    <span v-show="showBadge" class="inline-flex items-center justify-center w-6 h-6 text-sm p-0 font-bold text-white bg-valence-light-blue-400 rounded">{{
                        notificationCount
                    }}</span>
                </div>
            </button>
        </template>
        <TeamAccordion v-if="isValencer && hasTeamMembers" :team="teamAccordionItem" />
        <template v-for="link in $staffLinks" :key="link.url">
            <component
                :is="link.is_inertia ? Link : 'a'"
                :href="link.url"
                class="text-base hover:font-medium hover:bg-valence-panehover flex flex-row items-center gap-5 leading-none rounded-lg p-2 first-of-type:mt-auto">
                <i class="bi text-valence-grey text-2xl" :class="link.icon"></i>
                {{ link.title }}
            </component>
        </template>
    </div>
</template>

<script>
export const MAIN_MENU_PANE = {
    NEW_CHAT: "new_chat",
    HOME: "home",
    HISTORY: "history",
    JOURNEYS: "journeys",
    PROFILE: "profile",
};

export const PANE_DATA = {
    [MAIN_MENU_PANE.HOME]: {
        id: MAIN_MENU_PANE.HOME,
        title: "Home",
        icon: "bi-house",
        url: "/",
    },
    [MAIN_MENU_PANE.HISTORY]: {
        id: MAIN_MENU_PANE.HISTORY,
        title: "Past Conversations",
        icon: "bi-calendar3",
        url: "past-conversations",
    },
    [MAIN_MENU_PANE.NEW_CHAT]: {
        id: MAIN_MENU_PANE.NEW_CHAT,
        title: "New Chat",
        icon: "bi-plus-circle-fill",
        url: "/coach/start/",
    },
    [MAIN_MENU_PANE.JOURNEYS]: {
        id: MAIN_MENU_PANE.JOURNEYS,
        title: "Journeys",
        icon: "bi-graph-up",
        component: JourneyPane,
    },
    [MAIN_MENU_PANE.PROFILE]: {
        id: MAIN_MENU_PANE.PROFILE,
        title: "My Profile",
        icon: "bi-person-circle",
        url: "/accounts/user-profile",
    },
};
</script>

<script setup>
import { Link } from "@inertiajs/vue3";
import { openUrl } from "~vue/utils.js";
import { CHATSOURCE } from "~vue/utils/constants.js";
import { computed, inject, onMounted, ref } from "vue";

import JourneyPane from "./JourneyPane.vue";
import TeamAccordion from "./TeamAccordion.vue";

const userDetails = inject("user_details");

const emit = defineEmits(["openPane"]);
const props = defineProps({
    enabledPanes: {
        type: Array,
        default: () => [],
    },
});

// Refs
const notificationCount = ref(0);

// Computed
const showBadge = computed(() => notificationCount.value > 0);

const panes = computed(() => props.enabledPanes.map((p) => PANE_DATA[p]));
const isValencer = userDetails.is_valencer;
const hasTeamMembers = computed(() => userDetails.team_members.length > 0);
const teamAccordionItem = computed(() => ({ title: "My Team", content: userDetails.team_members }));

// Methods
const getNotifications = async () => {
    try {
        const response = await fetch("/accounts/profile-notifications/", {
            method: "GET",
            credentials: "include",
        });
        const data = await response.json();
        return data.notification_count || 0;
    } catch (error) {
        if ("Sentry" in window) {
            window.Sentry.captureException(new Error("Failed to get Profile Notifications."));
        }
        return 0;
    }
};

const fetchData = async () => {
    // Fetch notifications
    notificationCount.value = await getNotifications();
};

const handlePaneClick = (item) => {
    if (item.url) {
        openUrl(item.url, { queryParams: { chat_source: CHATSOURCE.LEFT_SIDEBAR } });
    } else {
        emit("openPane", item);
    }
};

onMounted(() => {
    document.addEventListener("valence:profileNotificationChange", fetchData);
    fetchData();
});
</script>
