<template>
    <div
        :id="popoverId"
        ref="popover"
        class="inset-[unset] absolute w-[280px] bottom-[4em] right-4 left-auto md:right-auto md:left-[1em] p-4 shadow-md border border-gray-50 rounded-xl"
        popover>
        <div class="flex flex-col gap-5">
            <SettingsMenuItems @item-click="hidePopover" />
        </div>
    </div>
</template>

<script setup>
import SettingsMenuItems from "./SettingsMenuItems.vue";
import { useTemplateRef } from "vue";

defineEmits("item-click");
defineProps({
    popoverId: {
        type: String,
        default: "",
    },
});
const popover = useTemplateRef("popover");
const hidePopover = () => popover.value?.hidePopover();

defineExpose({ hideDialog: hidePopover });
</script>
